function sanitizeUrl(urlString) {
  let url = new URL(urlString);
  const group_code = url.searchParams.get("group_code");
  const step = url.searchParams.get("step");

  // remove all params except step & group_code
  url.search = "";
  if (step !== null) {
    url.searchParams.set("step", step);
  }
  if (group_code !== null) {
    url.searchParams.set("group_code", "ID");
  }

  // hide idea id
  const match_idea = /\/ideas\/([^/]+)\/(.*)/gm;
  const subst_idea = "/ideas/ID/$2";
  url.href.replace(match_idea, subst_idea);

  // hide token from party answers
  const match_party_token = /\/parties\/([^/]+)(.*)/g;
  const subst_party_token = "/parties/TOKEN$2";
  url.href.replace(match_party_token, subst_party_token);

  return url;
}

export function sendPageEvent() {
  // If the analytics was not loaded, we simply return
  if (window.digitalData === undefined) return;

  const sanitized = sanitizeUrl(window.location.href);

  const pageName = `tools${sanitized.pathname + sanitized.search}`;

  const dnow = Date.now();

  const event = {
    event: {
      schema: "iglu:be.vrt/page_impression/jsonschema/1-1-0",
      data: {
        timestamp: dnow,
      },
    },
    context: [
      {
        schema: "iglu:be.vrt/context_page/jsonschema/1-2-1",
        data: {
          pagename: pageName,
          touchpointbrand: "vrt",
          contentbrand: "edubox",
          pageurl: sanitized.href,
          pagetitle: "EDUbox Burgerschap: tool",
          domain: "www.vrt.be",
          pagepath: pageName,
          pagelanguage: "nl",
          sitesection1: "",
          sitesection2: "",
          sitesection3: "EDUbox Burgerschap",
          sitesection4: "",
          pageid: pageName,
        },
      },
    ],
  };

  window.digitalData.events.push(event);
}
