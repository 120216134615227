import { sendPageEvent } from "../lib/analytics";

document.addEventListener("turbo:load", () => {
  // We try to detect when the analytics are actually loaded AND parsed
  // That way we know when to sent out the current page event
  let pageEventInterval = setInterval(() => {
    if (window.digitalData) {
      sendPageEvent();
      clearInterval(pageEventInterval);
    }
  }, 100);
});
